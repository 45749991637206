@import "styles/styles.scss";
.sizeChart {
  background-color: $color-neutral-light;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  margin-bottom: 50px;
  width: 100%;

  @include tablet-up {
    padding-bottom: 50px;
    margin-bottom: 80px;
  }

  .sizeChart__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: $max-width;
    position: relative;
    width: 100%;
  }

  .sizeChart__dropdown {
    justify-content: center;
    display: flex;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
    visibility: hidden;

    @include tablet-up {
      margin-bottom: 20px;
    }

    &.sizeChart__dropdown_visible {
      height: 55px;
      visibility: visible;
      overflow: visible;

      @include tablet-up {
        margin-bottom: 50px;
      }
    }
  }

  .sizeChart__wrapper {
    max-width: 678px;
    width: 100%;

    @include tablet-up {
      max-width: 750px;
      padding: 0 14px;
    }

    @include desktop-up {
      max-width: 850px;
      padding: 0;
    }
  }

  .sizeChart__close {
    border: none;
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;

    @include tablet-up {
      top: 30px;
      right: 30px;
    }

    @media (min-width: $max-width + 28px) {
      right: 0;
    }

    svg {
      width: 20px;
      height: 20px;

      @include tablet-up {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.linkCopy {
  color: $color-secondary;
  text-decoration: underline;
}

.sizeChart__productsDropdown {
  width: 311px;
}
